import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import styles from './Services.module.scss'
import { Button, Card, BackgroundWrapper, DotGrid } from '../../components/'
import classnames from 'classnames'
import clock from '../../images/clock.png'
import bitmap from '../../images/Bitmap.png'
import arionLogo from '../../images/arion-logo.svg'
import { LanguageStrings } from '../../Context'

const Services = ({ location }) => {
  const texts = useContext(LanguageStrings)

  return (
    <div className={classnames(styles.container)}>
      <Helmet>
        <meta property='og:title' content='Þjónusta :: vefhönnun, hughrif og fallegur kóði' />
        <meta property='og:description' content='Þjónusta :: vefhönnun, hughrif og fallegur kóði - Kosmos & Kaos :: hönnunarstofa með fókus á stafræn viðskipti' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={location ? location.href : null} />
      </Helmet>
      <section className='py-6'>
        <div className='container'>
          <BackgroundWrapper
            elements={[
              {
                top: '-38px',
                left: '-40px',
                offsetY: '10%',
                style: {
                  zIndex: -1
                },
                component: <DotGrid type='plus' volumeX={19} volumeY={12} />
              }
            ]}
          >
            <div className='row'>
              <div className='col-md-10 offset-md-1'>
                <h1 dangerouslySetInnerHTML={{ __html: texts.service.service_header_main }} />
                <h2 className='headline-text' >{ texts.service.service_header_under }</h2>
              </div>
            </div>
          </BackgroundWrapper>
        </div>
      </section>
      <section className='py-5'>
        <div className='container'>
          <section>
            <BackgroundWrapper
              elements={[
                {
                  top: '-25%',
                  left: '3%',
                  component: <div className='d-none d-md-block'><img src={clock} alt='clock' /></div>
                }
              ]}
            >
              <div className='row'>
                <div className='col-md-5 offset-md-5'>
                  <h2>{ texts.service.time_title}</h2>
                </div>
                <div className='col-md-6 offset-md-5'>
                  <p dangerouslySetInnerHTML={{ __html: texts.service.time_text }} />
                </div>
              </div>
            </BackgroundWrapper>
          </section>
        </div>
      </section>
      <section className='py-5'>
        <div className={classnames(styles.innerContainer, 'container')}>
          <div className='col-md-7 mb-4'>
            <h2>{texts.service.service_title}</h2>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <Card
              theme='yellow'
              className='swiper-slide col-xl-3 col-sm-6 mb-3'
              subtitle={texts.service.consultancy_text}
            >
              <div className='headline-description p-3 pt-4'>
                <h2>{texts.service.consultancy_title}</h2>
              </div>
            </Card>
            <Card
              theme='yellow'
              className='swiper-slide col-xl-3 col-sm-6 mb-3'
              subtitle={texts.service.strategies_text}
            >
              <div className='headline-description p-3 pt-4'>
                <h2>{texts.service.strategies_title}</h2>
              </div>
            </Card>
            <Card
              theme='yellow'
              className='swiper-slide col-xl-3 col-sm-6 mb-3'
              subtitle={texts.service.design_text}
            >
              <div className='headline-description p-3 pt-4'>
                <h2>{texts.service.design_title}</h2>
              </div>
            </Card>
            <Card
              theme='yellow'
              className='swiper-slide col-xl-3 col-sm-6 mb-3'
              subtitle={texts.service.programming_text}
            >
              <div className='headline-description p-3 pt-4'>
                <h2>{texts.service.programming_title}</h2>
              </div>
            </Card>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <h2>{ texts.service.results_title }</h2>
          <div className={'d-flex justify-content-between align-items-center mb-3'}>
            <p>{ texts.service.results_text }</p>
            <Button className='d-none d-lg-inline-block' to='/verkefnin'>{ texts.buttons.all_projects }</Button>
          </div>
        </div>
        <div className={styles.featuredProject}>
          <div className='container pt-md-7 pt-6 pb-5'>
            <BackgroundWrapper
              elements={[
                {
                  bottom: '25%',
                  right: '-180px',
                  component: <img src={bitmap} alt='bitmap' style={{ width: '72%' }} />
                },
                {
                  bottom: '300px',
                  component: <img src={arionLogo} alt='arionLogo' />
                }
              ]}
            >
              <h2 className='col-lg-12'>{ texts.service.arion_title }</h2>
              <div className='col-lg-12 col-m-6'>
                <h4 className='font-weight-normal pb-4'>{ texts.service.arion_text }</h4>
                <Button className={styles.featuredButton} to='/verkefnin/arionbanki'>{ texts.buttons.in_detail }</Button>
              </div>
            </BackgroundWrapper>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Services
