import Blog from './Blog'
import Project from './Project'
import Contact from './Contact'
import Preview from './Preview'
import AboutUs from './AboutUs'
import Services from './Services'
import Projects from './Projects'
import BlogArticle from './BlogArticle'
import Home from './Home'

export {
  Blog,
  Project,
  Contact,
  Preview,
  AboutUs,
  Services,
  Projects,
  BlogArticle,
  Home
}
