import React, { Component } from 'react'
import Cmp from './AboutUs'
import { StaticQuery, graphql } from 'gatsby'

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`{
          wordpressPage(wordpress_id: {eq: 239}) {
            featured_media {
              localFile {
                childImageSharp {
                  fixed(width: 1080, height: 1080, quality: 92) {
                    tracedSVG
                    aspectRatio
                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    originalName
                  }
                }
              }
            }
            yoast_title
            yoast {
              metadesc
            }
          }
          allWordpressWpEmployees {
            edges {
              node {
                title
                acf {
                  job_description
                  image {
                    localFile {
                      childImageSharp {
                        large: fixed(width: 322, height: 429, quality: 92) {
                          tracedSVG
                          aspectRatio
                          width
                          height
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          originalName
                        }
                        small: fixed(width: 178, height: 286, quality: 92) {
                          tracedSVG
                          aspectRatio
                          width
                          height
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          originalName
                        }                        
                      }
                    }
                  }
                }
              }
            }
          }
          allWordpressWpVerdlaun {
            edges {
              node {
                title
                featured_media {
                  localFile {
                    publicURL
                    childImageSharp {
                      fixed(width: 322, quality: 92) {
                        tracedSVG
                        aspectRatio
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        originalName
                      }
                    }
                  }
                }
              }
            }
          }
          allWordpressWpCustomers {
            edges {
              node {
                title
                featured_media {
                  localFile {
                    publicURL
                    childImageSharp {
                      fixed (width: 322, quality: 92) {
                        tracedSVG
                        aspectRatio
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        originalName
                      }
                    }
                  }
                }
              }
            }
          }
        }`}

        render={data => (
          <Cmp
            wordpressPage={data.wordpressPage}
            employees={data.allWordpressWpEmployees.edges.map(edge => edge.node)}
            customers={data.allWordpressWpCustomers.edges.map(edge => edge.node)}
            awards={data.allWordpressWpVerdlaun.edges.map(edge => edge.node)}
            {...this.props}
          />
        )}
      />
    )
  }
}

export default Connected
