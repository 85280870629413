import React, { Component } from 'react'
import fetch from 'isomorphic-fetch'
import BlogArticle from '../BlogArticle'

class Preview extends Component {
  constructor (props) {
    super(props)
    this.state = {
      post: null,
      error: false,
      message: ''
    }
  }

  componentDidMount () {
    const url = new URL(window.location.href)
    const previewToken = url.searchParams.get('preview-token')
    if (previewToken) {
      this.getPreview(previewToken)
    }
  }

  getPreview (previewToken) {
    const url = new URL(window.location.href)
    const apiBaseUrl = 'dev-api.kosmosogkaos.is'

    fetch('http://' + apiBaseUrl + '/wp-json/kosmos/v1/preview/' + previewToken)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          this.setState({
            post: null,
            error: response.error,
            message: response.message
          })
        } else {
          this.setState({
            post: response,
            error: false,
            message: ''
          })
        }
      })
  }

  render () {
    const { post, error, message } = this.state

    const title = post !== null ? post.post_title : error ? 'Error' : ''
    const content = post !== null ? post.post_content : error && message ? message : ''

    return (
      <BlogArticle
        pageContext={{
          title: title,
          content: content
        }}
      />
    )
  }
}

export default Preview
