import React from 'react'

const KrotLinaThunn = props => (
  <svg viewBox='0 0 88 375' {...props} >
    <title>krotlina-thunn</title>
    <g id='Design' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.100000001'>
      <g id='Verkefni-/-detail' transform='translate(-299.000000, -1830.000000)' {...props}>
        <g id='texti' transform='translate(168.000000, 1830.000000)'>
          <path d='M-7.67581005,246.433594 C103.377785,182.084896 228.214966,136.033627 357.031221,128' id='krotlina-thunn' transform='translate(174.677706, 187.216797) rotate(-59.000000) translate(-174.677706, -187.216797) ' />
        </g>
      </g>
    </g>
  </svg>
)

export default KrotLinaThunn
