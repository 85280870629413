import React, { Component } from 'react'
import Cmp from './Blog.js'
import { StaticQuery, graphql } from 'gatsby'

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`{
          wordpressPage(wordpress_id: {eq: 239}) {
            yoast_title
        }
          allInstaNode (limit: 6) {
            edges {
              node {
                likes
                id
                localFile {
                  childImageSharp {
                    fixed (width: 210, height: 210, quality: 92) {
                      tracedSVG
                      aspectRatio
                      width
                      height
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      originalName
                    }
                  }
                }
              }
            }
          }
          allWordpressPost(filter: {wordpress_id: {ne: 1900}}) {
            edges {
              node {
                slug
                title
                date
                author {
                  name
                  avatar_urls {
                    wordpress_96
                  }
                }
                acf {
                  post_main_image {
                    localFile {
                      childImageSharp {
                        large: fixed(height: 338, width: 545, quality: 92) {
                          tracedSVG
                          aspectRatio
                          width
                          height
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          originalName
                        }
                        small: fixed(height: 225, width: 333, quality: 92) {
                          tracedSVG
                          aspectRatio
                          width
                          height
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          originalName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`}
        render={data => (
          <Cmp data={{
            blogs: data.allWordpressPost.edges.map(edge => edge.node),
            wordpressPage: data.wordpressPage,
            instagramPosts: data.allInstaNode.edges.map(edge => edge.node)
          }} {...this.props} />)}
      />
    )
  }
}

export default Connected
