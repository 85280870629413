import React from 'react'

const KrotLinaThykk = props => (
  <svg viewBox='0 0 136 325' {...props} >
    <title>krotlina-thykk</title>
    <desc>Created with Sketch.</desc>
    <g id='Design' stroke='none' strokeWidth='1' fillRule='evenodd' opacity='0.100000001'>
      <g id='Verkefni-/-detail' transform='translate(-243.000000, -1881.000000)' {...props} strokeWidth='23' >
        <g id='texti' transform='translate(168.000000, 1830.000000)'>
          <path d='M-21.7073496,249.10031 C37.4801535,219.943044 114.804029,211.827175 176.534838,200.856169 C267.537446,184.682882 250.271676,186.883201 309.712572,182.238982' id='krotlina-thykk' transform='translate(144.002611, 215.669646) rotate(-59.000000) translate(-144.002611, -215.669646) ' />
        </g>
      </g>
    </g>
  </svg>
)

export default KrotLinaThykk
