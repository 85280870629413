import React, { useContext } from 'react'
import { get } from 'lodash'
import Helmet from 'react-helmet'
import classnames from 'classnames'
import styles from './AboutUs.module.scss'
import { Card, Slider, LogoDisplay, BackgroundWrapper, DotGrid, Ratio } from '../../components/'
import miro from '../../images/miro-1.svg'
import linaFront from '../../images/lina-forsida.svg'
import sky from '../../images/sky.png'
import Musastigi from '../../images/svg-components/Musastigi'
import Hjarta from '../../images/svg-components/Hjarta'
import zenno from '../../images/zenno.svg'
import lina from '../../images/lina1.svg'
import { LanguageStrings } from '../../Context'
import { getJobDescription } from '../../misc/utilities'

const AboutUs = ({ employees, customers, awards, wordpressPage, location }) => {
  const image = get(wordpressPage, 'featured_media.localFile.childImageSharp.fixed.src')
  const texts = useContext(LanguageStrings)
  const currentLang = texts.lang

  return (
    <div className={classnames(styles.container, 'pb-xl')}>
      <Helmet>
        <meta property='og:title' content={wordpressPage.yoast_title} />
        <meta property='og:description' content={wordpressPage.yoast.metadesc} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={location.href} />
        {image &&
          <meta property='og:image' content={image} />
        }

      </Helmet>
      <section className='py-6'>
          <div className='container'>
            <BackgroundWrapper
              elements={[
                {
                  top: '-7%',
                  left: '-31px',
                  offsetY: '10%',
                  style: {
                    zIndex: -1
                  },
                  component: <DotGrid volumeX={20} volumeY={12} type='plus' />
                }
              ]}
            >
              <div className='row'>
                <div className='col-md-10 offset-md-1'>
                  <h1>{ texts.aboutUs.main_header }</h1>
                  <h2 className='headline-text'>{ texts.aboutUs.under_header }</h2>
                </div>
              </div>
            </BackgroundWrapper>
          </div>
      </section>
      <section className='py-6'>
        <div className='container'>
          <div className='row'>
            <div className='offset-md-5 col-md-6'>
                <h2>{ texts.aboutUs.context_header }</h2>
                <p dangerouslySetInnerHTML={{ __html: texts.aboutUs.context }} />
            </div>
          </div>
        </div>
      </section>
      <section className='py-6'>
        <div className='container'>
          <BackgroundWrapper
            elements={[
              {
                top: '10%',
                left: '-50px',
                offsetY: '10%',
                style: {
                  zIndex: -1
                },
                component: <DotGrid volumeX={20} volumeY={12} type='plus' />
              }
            ]}
          >
            <h2>{ texts.aboutUs.staff }</h2>
            <div className='row'>
              {employees.map((employee, index) => {
                const jobDescription = getJobDescription(employee.acf.job_description, currentLang)
                return (
                  <div key={index} className='col-6 col-md-3 mb-4'>
                    <Card
                      title={employee.title}
                      image={employee.acf.image.localFile.childImageSharp}
                      ratio={1.3322981366}
                      jobDescription={jobDescription}
                    />
                  </div>
                )
              })}
              <div className='col-6 col-md-3 mb-4' style={{ height: '100%' }}>
                <Card
                  theme='yellow'
                  title=' '
                >
                  <Ratio ratio={1.3322981366}>
                    <div className='subtitle-description p-3 p-md-4'>
                      <h2>{ texts.aboutUs.apply }</h2>
                      <h2 className={styles.linkWrap}><a href='mailto:job@kosmosogkaos.is'>{ texts.aboutUs.apply_mail }</a></h2>
                    </div>
                  </Ratio>
                </Card>
              </div>
            </div>
          </BackgroundWrapper>
        </div>
      </section>
      <section className='py-6'>
        <div className='container'>
          <div className='row mb-4'>
            <div className='offset-md-5'>
                <h2>{ texts.aboutUs.clients }</h2>
                <p dangerouslySetInnerHTML={{ __html: texts.aboutUs.clients_text }} />
            </div>
          </div>
        </div>
        <Slider
          slidesPerView={4}
        >
          {customers.map((customer, index) => (
            <LogoDisplay
              key={index}
              className='swiper-slide'
              link='/'
              title={customer.title}
              image={get(customer, 'featured_media.localFile.publicURL', {})}
              ratio={1.3323}
            />
          ))}
        </Slider>
      </section>
      <section className='pt-6'>
        <div className='container'>
            <div className='row mb-4'>
              <div className='offset-md-5'>
                <div className='row'>
                  <h2 className='col-lg-6'>{ texts.aboutUs.awards }</h2>
                  <p className='col-lg-12'>{ texts.aboutUs.awards_context }</p>
                </div>
              </div>
            </div>
        </div>
        <Slider
          slidesPerView={4}
        >
          {awards.map((award, index) => (
            <LogoDisplay
              key={index}
              link='/'
              className='swiper-slide'
              isBlue
              title={award.title}
              image={get(award, 'featured_media.localFile.publicURL', {})}
              ratio={1.3323}
            />
          ))}
        </Slider>
      </section>
    </div>
  )
}

export default AboutUs
