import React, { Component} from 'react'
import { get } from 'lodash'
import { BlogCard, Button, InstaPreview, Slider } from '../../components'
import Helmet from 'react-helmet'
import { withLanguage } from '../../Context'

class Blog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      firstSectionCount: 4,
      lastSectionCount: 4
    }
  }

  render () {
    const { data: { blogs, instagramPosts } } = this.props
    const { firstSectionCount, lastSectionCount } = this.state
    const blogFirstSection = blogs.slice(0, firstSectionCount)
    const blogLastSection = blogs.slice(firstSectionCount, firstSectionCount + lastSectionCount)
    const texts = this.props.language

    return (
      <div className='pb-xl' >
        <Helmet>
          <meta property='og:title' content='Blog' />
          <meta property='og:type' content='website' />
          <meta property='og:url' content={this.props.location.href} />
        </Helmet>
        <section className='container'>
          <div className='row pt-6'>
            <div className='col-md-10 offset-md-1'>
              <h1 className='mb-0'>{texts.blog}</h1>
            </div>
          </div>
        </section>
        <section className='container'>
          <div className='row py-6'>
            <div className='col-md-10 offset-md-1'>
              <BlogRow blogs={blogFirstSection} />
            </div>
          </div>
        </section>
        <section className='container'>
          <h2>{texts.new_on_instagram}</h2>
        </section>
        <Slider
          allowTouchMove={false}
          grabCursor={false}
          slidesPerView={6}
          breakpoints={{
            768: {
              slidesPerView: 1.5,
              allowTouchMove: true,
              grabCursor: true
            }
          }}>
          {instagramPosts.map((post, index) => (
            <InstaPreview
              key={index}
              className='swiper-slide'
              image={post.localFile.childImageSharp.fixed}
              likes={post.likes}
              id={post.id}
            />
          ))}
        </Slider>
        <section className='container'>
          <div className='row pt-6'>
            <div className='col-md-10 offset-md-1'>
              <BlogRow blogs={blogLastSection} />
              {firstSectionCount + lastSectionCount < blogs.length && (
                <Button
                  onClick={() => {
                    this.setState({ lastSectionCount: lastSectionCount + 4 })
                  }}
                >{texts.buttons.see_more}</Button>
              )}
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const BlogRow = ({ blogs }) => (
  <div className='row'>
    {blogs.map((blog, index) => (
      <div key={index} className='col-md-6 mb-5'>
        <BlogCard
          title={blog.title}
          author={blog.author}
          date={blog.date}
          href={'/blog/' + blog.slug}
          image={get(blog, 'acf.post_main_image.localFile.childImageSharp', {})}
        />
      </div>
    ))}
  </div>
)

export default withLanguage(Blog)
