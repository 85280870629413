// This component is not being used

import React from 'react'
import styles from './Contact.module.scss'
import Helmet from 'react-helmet'

const Contact = ({ location }) => {
  return (
    <div className={styles.container}>
      <Helmet>
        <meta property='og:title' content='Hafa samband - Kosmos & Kaos :: hönnunarstofa með fókus á stafræn viðskipti' />
        <meta property='og:description' content='Verið hjartanlega velkomin á vinnustofur okkar. Við lofum góðu kaffi, góðu andrúmslofti og síðast en ekki síst, góðu fólki. Líka hægt að bjalla eða maila.' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={location.href} />
      </Helmet>
      <section className='pb-xl'>
        <div className='container pt-6'>
          <div className='row'>
            <div className='col-md-10 offset-md-1'>
              <h1>Hafa samband við okkur </h1>
              <h2 className='headline-text'>Síminn er <a href='tel:5611020'>561-1020</a> og netfangið er <a href='mailto:info@kosmosogkaos.is'>info@kosmosogkaos.is</a></h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact
