import React, { Component } from 'react'
import classnames from 'classnames'
import styles from './Project.module.scss'
import { get, debounce } from 'lodash'
import { DeviceSlider, ProjectHero, Comment, ContentSection, DeviceWrapper, Card, Slider, Image, BackgroundWrapper } from '../../components'
import Helmet from 'react-helmet'
import { hexToRGBA, getImageObj } from '../../misc/utilities'
import { ConsumerWrapper, withLanguage } from '../../Context'
import KrotLinaThykk from '../../images/svg-components/KrotLinaThykk'
import KrotLinaThunn from '../../images/svg-components/KrotLinaThunn'

class Project extends Component {
  componentDidMount () {
    const { pageContext: { acf = {} }, consumerContext } = this.props
    consumerContext.set({
      navigationTransparent: true,
      bgColor: hexToRGBA(acf.custom_color, 0.1),
      logoColor: '#fff'
    })
  }

  componentWillUnmount () {
    const { consumerContext } = this.props
    consumerContext.set({
      navigationTransparent: false,
      bgColor: undefined,
      logoColor: undefined,
      logoScrollColors: []
    })
  }

  render () {
    const { pageContext: { title, acf = {}, yoastTitle, metadesc, moreProjects }, consumerContext } = this.props
    const image = getImageObj(get(acf, 'image.sizes', {}))
    const href = this.props.location.href
    const texts = this.props.language

    return (
      <div>
        <Helmet>
          <meta property='og:title' content={yoastTitle ? yoastTitle.replace(/&amp;/g, '&') : yoastTitle} />
          {metadesc &&
            <meta property='og:decription' content={metadesc} />
          }
          <meta property='og:type' content='website' />
          {image &&
            <meta property='og:image' content={image} />
          }
          {href &&
            <meta property='og:url' content={href} />
          }
        </Helmet>
        <ProjectHero
          containerRef={ref => { this.heroRef = ref }}
          color={acf.custom_color}
          onResize={debounce((w, h) => {
            consumerContext.set({
              logoScrollColors: [{
                color: acf.custom_color,
                scrollValue: h
              }]
            })
          }, 300)}
        >
          {acf.verkefni_icon && (
            <img
              src={acf.verkefni_icon}
              alt=''
              className={classnames('mb-3', styles.projectLogo)}
            />
          )}
          <h1>{title}</h1>
          <h3 className='font-weight-normal mb-0'>{acf.verkefni_intro_texti}</h3>
        </ProjectHero>
        {acf.content_page && <Section sectionData={acf.content_page} color={acf.custom_color} />}
        <section className='py-6'>
          <div className='container'>
            <h2>{ texts.buttons.see_more }</h2>
          </div>
          <Slider
            allowTouchMove={false}
            grabCursor={false}
            slidesPerView={3}
            breakpoints={{
              768: {
                slidesPerView: 1.5,
                allowTouchMove: true,
                grabCursor: true
              }
            }}>
            {moreProjects.map((project, index) =>
              <Card
                key={index}
                image={getImageObj(get(project, 'acf.image.sizes', {}), 'card')}
                alt={project.title.rendered}
                title={project.title.rendered}
                bordered
                linkClassName='swiper-slide'
                linkTo={`/verkefnin/${project.slug}`}
              />
            )}
          </Slider>
        </section>
      </div>
    )
  }
}

const Section = ({ sectionData, color }) => {
  return sectionData.map((section, index) => {
    switch (section.acf_fc_layout) {
      case 'device_slider': {
        return (
          <section key={index} className='my-6'>
            <DeviceSlider slides={section.device_slider.map(slide => {
              const { img, ...rest } = slide
              return {
                img: get(img, 'sizes', ''),
                ...rest
              }
            })} />
          </section>
        )
      }
      case 'large_image': {
        return (
          <section key={index} className='my-6'>
            <div className='container'>
              {section.image &&
                <img className={styles.largeImage} src={get(section, 'image.sizes.full_image', '')} alt={section.image.title} />
              }
            </div>
          </section>
        )
      }
      case 'normal_content_area': {
        return (
          <BackgroundWrapper
            key={index}
            elements={[
              {
                top: '-10%',
                left: '30%',
                component: <KrotLinaThykk style={{ height: 285, stroke: `${color}` }} />
              },
              {
                top: '-27.5%',
                left: '32.5%',
                component: <KrotLinaThunn style={{ height: 550, width: 77, stroke: `${color}` }} />
              }
            ]}
          >
            <section
              className='my-6'
            >
              <ContentSection
                title={section.title}
                html={section.text}
                image={get(section, 'image.sizes.large', '')}
                link={{
                  title: section.link_name,
                  href: section.link_url
                }}
                color={color}
              />
            </section>
          </BackgroundWrapper>
        )
      }
      case 'device_images': {
        if (!section.images) {
          return null
        }
        const images = section.images.map((item, index) => {
          if (item.device === 'laptop') {
            return (
              <section
                key={index}
                className='my-6'
              >
                <div
                  style={{
                    maxWidth: 1200,
                    marginRight: 'auto',
                    marginLeft: 'auto'
                  }}
                >
                  <DeviceWrapper
                    image={getImageObj(get(item, 'image.sizes', {}), 'large')}
                    deviceType='macbook'
                  />
                </div>
              </section>
            )
          } else if (item.device === 'mobile') {
            return (
              <section
                key={index}
                className='my-6'
              >
                <div
                  style={{
                    maxWidth: 270,
                    marginRight: 'auto',
                    marginLeft: 'auto'
                  }}
                >
                  <DeviceWrapper
                    image={getImageObj(get(item, 'image.sizes', {}), 'large')}
                    deviceType='iphone'
                  />
                </div>
              </section>
            )
          }
        })
        return images
      }
      case 'review': {
        return (
          <section key={index} className='container my-6'>
            <div className='row'>
              <div className='col-lg-10 col-xl-9 offset-xl-1'>
                <Comment
                  name={section.name}
                  title={section.position}
                  image={{
                    src: get(section, 'image.sizes.thumbnail')
                  }}
                >
                  {section.review}
                </Comment>
              </div>
            </div>
          </section>
        )
      }
      default: {
        return null
      }
    }
  })
}

export default withLanguage(ConsumerWrapper(Project, ['set']))
